import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { Launch } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { environmentsService, wsService } from "services";
import { useFavorites, useRecentlyVisited } from "store";

import {
  GitRefIcon,
  PullRequestChip,
  Subtitle,
  Title,
  EnvStatusIcon,
  CommitLink,
  Terminal,
  FavoriteButton,
  EnvRunningStatusIcon,
  EnvTitle,
} from "components/atoms";
import { EnvActions, EnvInfo } from "components/molecules";
import { ActivityLogs, ServiceLists, TestLists } from "components/organisms";

import NormalPage from "./layout/page";

export default function Environment() {
  let params = useParams();
  const navigate = useNavigate();

  const {
    data: env,
    isLoading,
    refetch: refreshEnv,
  } = environmentsService.useEnv(params.envId);

  wsService.useEnvSubscription(params.envId ?? "missing", refreshEnv);

  const entryListItem = useMemo(
    () =>
      ({
        type: "environment",
        data: { ...env, repoId: params.repoId, groupId: params.groupId },
        visitedId: env?.id,
      }) as const,
    [env, params.groupId, params.repoId]
  );

  useRecentlyVisited(entryListItem);
  const { toggleFavorite, isFavorite } = useFavorites(entryListItem);

  const [buildLogsKey, setBuildLogsKey] = useState<string>(
    Math.random().toString()
  );
  useEffect(() => {
    setBuildLogsKey(Math.random().toString());
  }, [env?.status]);

  const statusColorClass = useMemo(() => {
    switch (env?.status) {
      case "softrebuilding":
      case "building":
        return "text-status-warning";
      case "failed":
        return "text-status-error";
      case "built":
        return "text-status-success";
      default:
        return "text-neutral";
    }
  }, [env?.status]);

  if (!env && !isLoading) {
    navigate("/404", { replace: true });
  }

  return (
    <NormalPage
      tabs={[
        <FavoriteButton
          toggleFavorite={toggleFavorite}
          isFavorite={isFavorite}
        />,
      ]}
      title={
        <div className="flex items-center gap-s">
          {env && <GitRefIcon {...env} size="xl" />}
          <div>
            <div className="flex gap-s">
              {!!env?.runningStatus && <EnvRunningStatusIcon {...env} />}
              <Title>{env ? EnvTitle(env) : ""}</Title>

              {!!env && <PullRequestChip {...env} />}
              <CommitLink
                label={env?.gitRef.hash}
                url={env?.gitRef.externalLink}
              />
            </div>
            {!!env && (
              <div className={"flex gap-x-s items-center flex-wrap"}>
                <div className={statusColorClass}>
                  <EnvStatusIcon {...env} />
                </div>
                <p className={"font-medium text-m " + statusColorClass}>
                  {env?.status}
                </p>
                <EnvInfo {...env} mode="details" />
              </div>
            )}
          </div>
        </div>
      }
    >
      <div className="flex gap-m bg-white p-s rounded shadow-sm flex-wrap mb-m">
        {env && <EnvActions {...env} />}
      </div>

      <TestLists tests={env?.tests || []} isLoading={isLoading} />

      <div className="flex flex-col md:grid md:grid-cols-[66%,33%] gap-m my-m">
        <div className="flex flex-col">
          <div className="flex items-center gap-s mb-m">
            <Subtitle>Build logs</Subtitle>
            <Link
              to={"/environment/" + params.envId + "/build/logs"}
              target={"_blank"}
            >
              <IconButton size="small">
                <Launch />
              </IconButton>
            </Link>
          </div>
          <Terminal
            className="p-s rounded shadow-sm"
            key={buildLogsKey}
            url={"/environment/" + params.envId + "/build/logs"}
          />
        </div>
        <div>
          <Subtitle>Activity logs</Subtitle>
          {!!env && <ActivityLogs {...env} />}
        </div>
      </div>

      <ServiceLists
        services={env?.services || []}
        isLoading={isLoading}
        refreshEnv={refreshEnv}
      />
    </NormalPage>
  );
}
