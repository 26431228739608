const getTimeDeltaString = (time: Date) => {
  return getTimeDeltaSinceString(time, new Date());
};

const getTimeDeltaEllapsedString = (time: Date) => {
  const now = new Date();
  // calculate time ellapsed
  const timeLeft = Math.floor((now.getTime() - time.getTime()) / 1000);

  if (timeLeft < 60) {
    return `${timeLeft}s`;
  }

  if (timeLeft < 3600) {
    const minutes = Math.floor(timeLeft / 60);
    return `${minutes}m ${timeLeft % 60}s`;
  }

  return `${Math.floor(timeLeft / 3600)}h ${Math.floor(timeLeft / 60) % 60}m ${Math.floor(timeLeft % 60)}s`;
};

const getTimeDeltaSinceString = (since: Date, until: Date) => {
  // calculate time left
  const timeLeft = Math.abs(
    Math.floor((until.getTime() - since.getTime()) / 60000)
  );

  // return time left in string format "x minutes" if less than an hour left, "x hours" if less than a day left, "x days" if less than a week left or "x days and y hours" if more than a day left
  if (timeLeft < 60) {
    return `${timeLeft}m`;
  }

  if (timeLeft < 1440) {
    const hours = Math.floor(timeLeft / 60);
    return `${hours}h`;
  }

  if (timeLeft / 1440 < 30) {
    const days = Math.floor(timeLeft / 1440);
    return `${days}d`;
  }

  if (timeLeft / 1440 >= 30 && timeLeft / 1440 < 365) {
    const months = Math.floor(timeLeft / 43200);
    return `${months}mo`;
  }

  const years = Math.floor(timeLeft / 525600);
  return `${years}y`;
};

const getTimeDeltaSince = (since: Date, until: Date) => {
  const millisLeft = Math.abs(until.getTime() - since.getTime());

  // return like MM:ss:mmmmm , always pad with 0
  const minutes = Math.floor(millisLeft / 60000);
  const seconds = Math.floor((millisLeft % 60000) / 1000);
  const milliseconds = millisLeft % 1000;
  return `${String(minutes)}:${String(seconds).padStart(2, "0")}.${String(
    milliseconds
  ).padStart(3, "0")}`;
};

export {
  getTimeDeltaString,
  getTimeDeltaSince,
  getTimeDeltaSinceString,
  getTimeDeltaEllapsedString,
};
