import Loader from "../Loader";
import { ImCheckmark, ImCross } from "react-icons/im";
import { FaExclamation } from "react-icons/fa6";


export default function TestStatusIcon(props: { status: string }) {
    switch (props.status) {
        case "running":
            return <Loader />
        case "finished":
        case "passed":
            return <ImCheckmark />
        case "error":
            return <FaExclamation />
        case "failed":
            return <ImCross />
        default:
            return null;
    }
}