import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaCircleQuestion } from "react-icons/fa6";

import { FitAddon } from "@xterm/addon-fit";
import { WebLinksAddon } from "@xterm/addon-web-links";
import { ClipboardAddon } from "@xterm/addon-clipboard";
import { Terminal as xterm } from '@xterm/xterm';
import "xterm/css/xterm.css";

import { NotificationsState } from "store";
import { API_WS_URL } from "services/config";

export default function Terminal(props: {
  url: string;
  enableWrite?: boolean;
  closeAuto?: boolean;
  fullscreen?: boolean;
  snackNotifications?: boolean;
  className?: string;
  autofocus?: boolean;
}) {
  const { enableWrite = false } = props;
  const termRef = useRef(null);
  const navigate = useNavigate();

  const { addNotification } = useContext(NotificationsState);

  useEffect(() => {
    if (props.fullscreen) document.title = "Qaack | Terminal";

    const terminal = new xterm({
      scrollback: 999999,
      fontFamily: "monospace",
    });

    const textDecoder = new TextDecoder();
    const textEncoder = new TextEncoder();

    const fitAddon = new FitAddon();
    const webLinksAddon = new WebLinksAddon();
    const clipboardAddon = new ClipboardAddon();

    if (termRef.current) {
      terminal.open(termRef.current);
      terminal.loadAddon(fitAddon);
      terminal.loadAddon(webLinksAddon);
      terminal.loadAddon(clipboardAddon);

      let socket = new WebSocket(API_WS_URL + props.url);
      socket.binaryType = "arraybuffer";

      socket.onopen = () => {
        socket.send(
          JSON.stringify({ width: terminal.cols, height: terminal.rows })
        );

        if (enableWrite) {
          terminal.onKey((e: any) => {
            socket.send(textEncoder.encode(e.key));
            return;
          });
        }

        terminal.onResize(() => {
          // wait for socket to be ready
          socket.send(
            JSON.stringify({ width: terminal.cols, height: terminal.rows })
          );
          if (props.snackNotifications)
            addNotification({
              type: "info",
              message: "Resized to " + terminal.cols + "x" + terminal.rows,
            });
        });

        fitAddon?.fit();
        window.onresize = () => {
          fitAddon?.fit();
        };

        if (props.snackNotifications)
          addNotification({
            type: "success",
            message: "Connected to " + props.url,
          });
      };

      socket.onerror = (err) => {
        if (props.snackNotifications)
          addNotification({
            type: "error",
            message: "Error connecting to " + props.url + ": " + err,
          });
      };

      socket.onmessage = (msg?: any) => {
        terminal.write(textDecoder.decode(msg.data));
      };

      socket.onclose = (ev) => {
        // normal close
        if (ev.code === 1000) {
          if (props.closeAuto) {
            window.close();
          }

          if (props.snackNotifications)
            addNotification({
              type: "info",
              message: "Connection complete",
            });

        } else if (ev.code === 4000) {
          // this means there is no auth
          if (props.fullscreen) navigate("/login?redirectTo=" + props.url);
        } else if (ev.code === 4001) {
          // this means the service has not been found
          terminal.write(ev.reason);
        } else {
          if (props.snackNotifications)
            addNotification({
              type: "error",
              message:
                "Connection was closed. Reason: " +
                ev.reason +
                ". Code: " +
                ev.code,
            });
        }
      };

      terminal.attachCustomKeyEventHandler((x: KeyboardEvent) => {
        // handle ctrl + shift + v
        try {
          if (
            x.ctrlKey &&
            x.key.toLowerCase() === "v" &&
            x.shiftKey &&
            x.type === "keydown"
          ) {
            // get clipboard data
            x.preventDefault();
            if (enableWrite) {
              navigator.clipboard.readText().then((clipText) => {
                socket.send(textEncoder.encode(clipText));
              });
            }
            return false;
          }

          // handle ctrl + shift + c
          if (
            x.ctrlKey &&
            x.key.toLowerCase() === "c" &&
            x.shiftKey &&
            x.type === "keydown"
          ) {
            // get clipboard data
            x.preventDefault();

            // get selected text
            const selectedText = terminal.getSelection();
            if (selectedText) {
              navigator.clipboard.writeText(selectedText);
            }
            return false;
          }

          // handle command + v
          if (
            x.metaKey &&
            x.key.toLowerCase() === "v" &&
            x.type === "keydown"
          ) {
            // get clipboard data
            x.preventDefault();

            if (enableWrite) {
              navigator.clipboard.readText().then((clipText) => {
                socket.send(textEncoder.encode(clipText));
              });
            }
            return false;
          }

          // handle command + c
          if (
            x.metaKey &&
            x.key.toLowerCase() === "c" &&
            x.type === "keydown"
          ) {
            // get clipboard data
            x.preventDefault();
            // get selected text
            const selectedText = terminal.getSelection();
            if (selectedText) {
              navigator.clipboard.writeText(selectedText);
            }
            return false;
          }

          return true;
        } catch (e) {
          console.error(e);
          addNotification({
            type: "error",
            message:
              "Something went wrong when copy or pasting text, please ensure your browser is updated and try again",
          });
          return true;
        }
      });

      if (props.autofocus) terminal.focus();
    }

    return () => {
      terminal.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termRef, props.url, enableWrite]);

  const heightClass = props.fullscreen ? "h-screen" : "h-[50vh]";

  return (
    <>
      {props.fullscreen && <Helper />}
      <div
        ref={termRef}
        className={"w-full bg-black " + props.className + " " + heightClass}
      />
    </>
  );
}


const Helper = () => {
  const { addNotification } = useContext(NotificationsState);

  const handleClick = () => {
    addNotification({
      type: "info",
      message: "You can copy and paste text using the following shortcuts: \n\n" +
        "Copy: Ctrl + Shift + C / Command + C\n" +
        "Paste: Ctrl + Shift + V / Command + V\n" +
        "If it does not work, please update your browser and try another one."
    });
  }

  return <FaCircleQuestion className="z-20 absolute right-m top-s text-gray-400" onClick={handleClick} />
}