import { useCallback, useMemo, useState } from "react";
import { Article, CompassCalibration, Lock, LockOpen, Terminal } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import prettyBytes from "pretty-bytes";

import { envTypes } from "types";
import { serviceService } from "services";

import { Loader, ServiceStatusIcon } from "components/atoms";
import Teaser from "./baseTeaser";

const ServiceTeaser = (service: envTypes.Service) => {
  const statusClasses = useMemo(
    () =>
      service.Status?.Running
        ? "!fill-status-success !text-status-success"
        : "!fill-status-error !text-status-error",
    [service.Status?.Running]
  );

  const {
    mutate: lockServ,
    isLoading: lockLoading,
  } = serviceService.useLock(service.id);

  const {
    mutate: unlockServ,
    isLoading: unlockLoading,
  } = serviceService.useUnlock(service.id);

  const [openUnlockDialog, setOpenUnlockDialog] = useState(false);

  const onToggleLock = useCallback(() => {
    if (service.locked) {
      setOpenUnlockDialog(true);
    } else {
      lockServ();
    }
  }, [service.locked, lockServ, setOpenUnlockDialog]);

  const Icon = useMemo(() =>
    lockLoading || unlockLoading
      ? <Loader size="small" />
      : service.locked
        ? <LockOpen />
        : <Lock />
    , [lockLoading, unlockLoading, service.locked]);

  return (
    <>
      <UnlockServiceDialog service={service} open={openUnlockDialog} unlock={() => {
        unlockServ();
        setOpenUnlockDialog(false);
      }}
        onClose={() => { setOpenUnlockDialog(false) }}
      />
      <Teaser
        icon={
          <CompassCalibration
            className={statusClasses}
          />
        }
        title={service.name}
        type={service.image}
        statusIcon={<ServiceStatusIcon {...service} />}
        status={service.Status?.Running ? "Running" : "Stopped"}
        statusColorClass={statusClasses}
        info={
          <>
            {service.locked && (
              <p className="font-black flex items-center"> <Lock fontSize="inherit" className="mr-[2px]" /> Locked</p>
            )}
            {!!service.Status?.Size && (
              <p>{prettyBytes(service.Status?.Size || 0)}</p>
            )}
          </>
        }
        actions={
          <>
            <Button
              onClick={onToggleLock}
              disabled={lockLoading || unlockLoading}
              startIcon={Icon}
            >
              {service.locked ? "Unlock" : "Lock"}
            </Button>
            <Link to={"/service/" + service.id + "/logs"} target="_blank">
              <Button startIcon={<Article />}>
                <span className="hidden sm:block">Logs</span>
              </Button>
            </Link>
            <Link to={"/service/" + service.id + "/console"} target="_blank">
              <Button startIcon={<Terminal />}>
                <span className="hidden sm:block">Console</span>
              </Button>
            </Link>
          </>
        }
      />
    </>
  );
};

const UnlockServiceDialog = (props: { unlock: () => void, service: envTypes.Service, open: boolean, onClose: () => void }) => {
  const [error, setError] = useState(false);
  const [input, setInput] = useState("");

  const onUnlock = useCallback(
    () => {
      if (input === props.service.name) {
        props.unlock();
      } else {
        setError(true);
      }
    }, [input, props, setError]
  );

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Unlock service</DialogTitle>
      <DialogContent>
        <p>
          Unlocking a sevice allows to delete it and the content it holds! Please confirm you want to unlock this service.
        </p>
        <p className="mb-m">
          Insert the service name ({props.service.name}) to confirm you want to unlock it:
        </p>
        <TextField
          autoFocus
          id="name"
          size="small"
          label="Service name"
          type="text"
          fullWidth
          onInput={(e) => setInput((e.target as HTMLInputElement).value)}
        />
        {error && <DialogContentText color="error">The service name is incorrect</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={input !== props.service.name}
          onClick={onUnlock}
        >
          Unlock
        </Button>
      </DialogActions>
    </Dialog >
  );
}

export { ServiceTeaser };
