import { useEffect, useMemo, useRef, useState } from "react";
import { Alert, Divider, Modal, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { Assessment } from "@mui/icons-material";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";
import pixelmatch from "pixelmatch";

import { testTypes } from "types";
import { DurationString } from "utils";
import { TestIcon, TestStatusIcon, Title } from "components/atoms";

import Teaser from "./baseTeaser";
import CircularProgressWithLabel from "../circularProgress";

const ImageResult = (result: testTypes.TestResult & { testId: string }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const src = `/api/test/${result.testId}/result/${result.label}`;

  return (
    <div className="relative">
      <img
        src={src}
        alt={result.label}
        onClick={() => setFullScreen(!fullScreen)}
        className="!h-xl w-auto hover:cursor-pointer"
      />
      <Modal open={fullScreen} onClose={() => setFullScreen(false)}>
        <img
          src={src}
          alt={result.label}
          className="bg-white p-m rounded w-auto h-2/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      </Modal>
    </div>
  );
};

const DiffHeatMap = (result: testTypes.TestResult) => {
  const baselineUrl = result.details?.baselineImage ?? "";
  const candidateUrl = result.details?.candidateImage ?? "";

  const [diffImage, setDiffImage] = useState<string | undefined>(undefined);
  const diffCanvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!baselineUrl || !candidateUrl) {
      return;
    }

    const baseline = new Image();
    const candidate = new Image();

    baseline.src = baselineUrl;
    candidate.src = candidateUrl;

    baseline.onload = () => {
      candidate.onload = () => {
        const width = Math.max(baseline.width, candidate.width);
        const height = Math.max(baseline.height, candidate.height);

        diffCanvas.current!.width = width;
        diffCanvas.current!.height = height;

        const context = diffCanvas.current!.getContext("2d")!;
        const diff = context.createImageData(width, height);

        const baselineCanvas = document.createElement("canvas");
        const candidateCanvas = document.createElement("canvas");

        baselineCanvas.width = baseline.width;
        baselineCanvas.height = baseline.height;
        candidateCanvas.width = candidate.width;
        candidateCanvas.height = candidate.height;

        const baselineContext = baselineCanvas.getContext("2d")!;
        const candidateContext = candidateCanvas.getContext("2d")!;

        baselineContext.drawImage(baseline, 0, 0);
        candidateContext.drawImage(candidate, 0, 0);

        const baselineData = baselineContext.getImageData(0, 0, baseline.width, baseline.height);
        const candidateData = candidateContext.getImageData(0, 0, candidate.width, candidate.height);

        // it returns the number of different pixels
        // writes into diff.data
        pixelmatch(
          baselineData.data,
          candidateData.data,
          diff.data,
          width,
          height,
          { threshold: 0.1 }
        );

        context.putImageData(diff, 0, 0);

        setDiffImage(diffCanvas.current!.toDataURL());
      };
    };
  }, [baselineUrl, candidateUrl]);

  return (
    <div className="relative">
      <canvas ref={diffCanvas} className="hidden" />
      <img src={diffImage ?? baselineUrl} alt="diff" />
    </div>
  );
};

const DiffSlider = (result: testTypes.TestResult) => {
  return <ReactCompareSlider
    onClick={(e) => { e.stopPropagation(); e.preventDefault() }}
    itemOne={
      <ReactCompareSliderImage src={result.details?.baselineImage} alt="baseline" />
    }
    itemTwo={<ReactCompareSliderImage src={result.details?.candidateImage} alt="candidate" />}
  />;
}

const VisualRegressionImage = (result: testTypes.TestResult & { threshold?: number }) => {
  // switch button between heatmap and slider
  const [showDiff, setShowDiff] = useState(false);
  const color = getPercentageColor(Number(result.value), Number(result.value) < (result.threshold ?? 0));
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDiff(!showDiff);
  }

  return (
    <div className="w-auto relative group" >
      <div className="flex items-center gap-s justify-between mb-s">
        <div className="flex items-center gap-s">
          <ToggleButtonGroup
            value={showDiff}
            exclusive
            onChange={onClick}
            size="small"
            color="primary"
          >
            <ToggleButton value={false}>Slider</ToggleButton>
            <ToggleButton value={true}>Heatmap</ToggleButton>
          </ToggleButtonGroup>
          <span className="text-neutral text-m">
            {result.label}
          </span>
        </div>
        <CircularProgressWithLabel
          color={color}
          size={40}
          values={[
            {
              value: Number(result.value),
              label: result.label,
            },
          ]}
        />
      </div>
      {showDiff ? <DiffHeatMap {...result} /> : <DiffSlider {...result} />}
      <Divider className="my-s" />
    </div>
  );
}


const getPercentageColor = (percentage: number, failed?: boolean) => {
  return failed ? "error" : percentage > 90 ? "primary" : "warning";
}

const VisualRegressionResult = (result: testTypes.TestResult & { testId: string, failed?: boolean, threshold?: number }) => {
  const percentage = Number(result.value ?? 0);
  const color = getPercentageColor(percentage, result.failed);

  const [fullScreen, setFullScreen] = useState(false);

  const firstThreeImages = result.results?.slice(0, 3).map((r) => r.details?.candidateImage);
  return (
    <div className="relative cursor-pointer" onClick={() => setFullScreen(!fullScreen)}>
      <div
        className="flex gap-l items-center"
      >
        <div className="relative flex">
          {firstThreeImages?.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={result.label}
              className={"rounded-sm shadow-md !h-xl w-auto left-0 " + (index > 0 ? "-ml-xl" : "")}
            />
          ))}
        </div>
        <CircularProgressWithLabel
          color={color}
          size={50}
          values={[
            {
              value: percentage,
              label: `Match Ratio`
            },
          ]}
          label={`Match Ratio`}
        />
      </div>

      <Modal
        open={fullScreen}
        onClose={() => setFullScreen(false)}
        className="flex items-center justify-center"
      >
        <div className="bg-white p-l rounded w-1/2 m-auto self-center max-h-[75%] h-[75%] overflow-scroll">
          <Title>Visual Regression Results</Title>
          <div className="flex flex-col gap-m mt-m">
            {result.results?.map((r) => (
              <VisualRegressionImage key={r.label} {...r} threshold={result.threshold} />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );

}


const PercentageResult = (result: testTypes.TestResult & { threshold?: number }) => {
  const percentage = Number(result.value);
  const color = getPercentageColor(percentage, percentage < (result.threshold ?? 0));
        
  return (
    <CircularProgressWithLabel
      color={color}
      size={50}
      values={[
        {
          value: percentage,
          label: result.label,
        },
      ]}
      label={result.label}
    />
  );
};

const NumberResult = (result: testTypes.TestResult) => {
  const number = Number(result.value);
  return (
    <div className="flex flex-col items-center">
      <p className="text-neutral text-m">{number}</p>
      <p className="text-neutral text-m">{result.label}</p>
    </div>
  );
};

const PlaywrightResult = (result: testTypes.TestResult) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [passed, failed] = result.value.split("/").map(Number);

  return (
    <div className="relative">
      <div
        className="flex gap-s items-center cursor-pointer"
        onClick={() => setFullScreen(!fullScreen)}
      >
        {passed > 0 && (
          <Alert severity="success">
            <p className="text-m font-bold">{passed} passed</p>
          </Alert>
        )}
        {failed > 0 && (
          <Alert severity="error">
            <p className="text-m font-bold">{failed} failed</p>
          </Alert>
        )}
      </div>

      <Modal
        open={fullScreen}
        onClose={() => setFullScreen(false)}
        className="flex items-center justify-center"
      >
        <div className="bg-white p-l rounded w-1/3 m-auto self-center flex flex-col gap-m max-h-[75%] overflow-scroll">
          <Title>Playwright Results</Title>
          {result.results?.map((r) => (
            <Alert
              key={r.label}
              severity={r.value === "true" ? "success" : "error"}
            >
              <p>{r.label}</p>
              <p>{r.value}</p>
            </Alert>
          ))}
        </div>
      </Modal>
    </div>
  );
};

const HtmlResult = (result: testTypes.TestResult & { testId: string }) => {
  const src = `/api/test/${result.testId}/result/${result.label}`;

  return (
    <a href={src} target="_blank" rel="noreferrer">
      <Assessment className="!text-brand" />
    </a>
  );
};

const TestResultFactory = (
  result: testTypes.TestResult & { testId: string, failed?: boolean, threshold?: number }
) => {
  if (result.hidden) {
    return null;
  }
  switch (result.type) {
    case "image":
      return <ImageResult {...result} />;
    case "number":
      return <NumberResult {...result} />;
    case "percentage":
      return <PercentageResult {...result} />;
    case "playwright-result":
      return <PlaywrightResult {...result} />;
    case "visual-regression":
      return <VisualRegressionResult {...result} />;
    case "html":
      return <HtmlResult {...result} />;
    case "string":
      return (
        <Alert severity="info">
          <Tooltip title={result.value}>
            <p>{result.value.toString().slice(0, 30)}...</p>
          </Tooltip>
        </Alert>
      );
    case "error":
      return (
        <Alert severity="error">
          <Tooltip title={result.value}>
            <p>{result.label}: {result.value ? result.value.slice(0, 30) + "..." : undefined}</p>
          </Tooltip>
        </Alert>
      );
    default:
      return (
        <p>
          {result.label}: {result.value}
        </p>
      );
  }
};

const processPlaywrightResults = (playwrightTest: testTypes.Test) => {
  const passed =
    playwrightTest.results?.filter((r) => r.value === "true").length ?? 0;
  const failed =
    playwrightTest.results?.filter((r) => r.value === "false").length ?? 0;
  return {
    ...playwrightTest,
    results: [
      {
        label: "Playwright Results",
        type: "playwright-result",
        value: `${passed}/${failed}`,
        results: playwrightTest.results?.filter((r) => r.type === "playwright-result"),
      },
      ...(playwrightTest.results?.filter((r) => r.type !== "playwright-result") ?? []),
    ],
  } as testTypes.Test
};

const processVisualRegressionResults = (visualRegressionTest: testTypes.Test) => {
  const allResults = visualRegressionTest.results ?? [];

  if (allResults.some((r) => r.type === "error")) {
    return visualRegressionTest;
  }

  const vrResults = allResults.filter((r) => r.type === "visual-regression");
  if (vrResults.length === 0) {
    // this is a baseline environment

    // check amount of images available
    const images = allResults.filter((r) => r.type === "image");
    if (images.length === 0) {
      return visualRegressionTest
    }

    const availablePaths = images.map((r) => r.label).join(", ");

    return {
      ...visualRegressionTest,
      results: [
        {
          label: "Status",
          type: "string",
          value: `(${images.length}) baseline screenshots ready to be compared. Available here: ${availablePaths}`,
        },
      ] as testTypes.TestResult[],
    }
  }

  const avgPercentage = Math.floor(vrResults.reduce(
    (acc, r) => acc + Number(r.value),
    0
  ) / vrResults.length);

  return {
    ...visualRegressionTest,
    results: [
      {
        label: "Visual Regression Results",
        type: "visual-regression",
        value: avgPercentage.toString(),
        results: vrResults.map((r) => ({
          ...r,
          details: {
            candidateImage: `/api/test/${visualRegressionTest.id}/result/${r.label}-candidate`,
            baselineImage: `/api/test/${visualRegressionTest.id}/result/${r.label}-baseline`,
          },
        })),
      },
    ],
  } as testTypes.Test;

};

const TestTeaser = (test: testTypes.Test) => {
  const statusClasses = useMemo(
    () =>
      test.status === "error" || test.results?.some((r) => r.type === "error") || test.status === "failed"
        ? "!fill-status-error !text-status-error"
        : (test.status === "finished" || test.status === "passed")
          ? "!fill-status-success !text-status-success"
          : test.status === "running"
            ? "!fill-status-warning !text-status-warning"
            : "!fill-neutral !text-status-neutral",
    [test.status, test.results]
  );

  const processedTest = useMemo(() => {
    switch (test.type) {
      case "playwright":
        return processPlaywrightResults(test);
      case "visual-regression":
        return processVisualRegressionResults(test);
      default:
        return test;
    }
  }, [test]);

  const testInfo = useMemo(() => {
    if (!processedTest.duration) {
      return undefined
    }

    switch (processedTest.status) {
      case "failed":
        return `after ${DurationString((processedTest.duration) / 1000)}`;
      default:
        return `in ${DurationString((processedTest.duration) / 1000)}`;
    }
  }, [processedTest])

  return (
    <Teaser
      icon={<TestIcon className={statusClasses} type={processedTest.type} />}
      title={processedTest.label}
      type={processedTest.type}
      status={processedTest.status}
      info={testInfo}
      statusIcon={<TestStatusIcon status={processedTest.status} />}
      statusColorClass={statusClasses}
      actions={
        <>
          {processedTest.results?.map((result: testTypes.TestResult) => (
            <TestResultFactory
              key={result.label}
              {...result}
              testId={test.id}
              failed={test.status === "failed"}
              threshold={test.threshold}
            />
          ))}
        </>
      }
    />
  );
};

export { TestTeaser };
