import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Loader from "./Loader";
import {
  ChangeCircleOutlined,
  CheckCircleOutlined,
  ErrorOutlined,
  SyncDisabled,
} from "@mui/icons-material";

const Indicator = (props: {
  updated?: boolean;
  error?: boolean;
  loading?: boolean;
  disabled?: boolean;
}) => {
  return (
    <InputAdornment position="start">
      {!props.updated && props.loading && <Loader />}
      {!props.error && !props.updated && !props.loading && (
        <ChangeCircleOutlined />
      )}
      {!props.loading && props.updated && !props.error && !props.disabled && (
        <CheckCircleOutlined className="!fill-brand" />
      )}
      {props.error && !props.loading && (
        <ErrorOutlined className="!fill-status-error" />
      )}
      {props.disabled && <SyncDisabled />}
      &nbsp;
    </InputAdornment>
  );
};

const SaveOnBlurInput = (props: {
  value?: string | number;
  onChange?: (value: string) => void;
  onBlur?: (newValue: string | number) => void;
  className?: string;
  placeholder?: string;
  type?: string;
  name?: string;
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  unit?: string;
  inputProps?: any;
}) => {
  const [localValue, setLocalValue] = useState<string | number | undefined>(
    props.value
  );

  const updated = useMemo(
    () => localValue === props.value,
    [localValue, props.value]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
    props.onChange?.(e.target.value);
  };

  const handleBlur = () => {
    props.onBlur?.(localValue || "");
  };

  useEffect(() => {
    setLocalValue(props.value);
  }, [props.value]);

  return (
    <TextField
      type={props.type}
      inputProps={props.inputProps}
      id={props.name}
      name={props.name}
      placeholder={props.placeholder}
      InputProps={{
        startAdornment: (
          <Indicator
            updated={updated}
            loading={props.loading}
            error={props.error}
            disabled={props.disabled}
          />
        ),
        endAdornment: props.unit ? (
          <InputAdornment position="end">{props.unit}</InputAdornment>
        ) : null,
      }}
      size="small"
      value={localValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={(e) => e.target.select()}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          (e.target as HTMLInputElement).blur();
        }
      }}
      disabled={props.disabled}
      className="ml-auto w-full"
    />
  );
};

export default SaveOnBlurInput;
