import { AxiosError } from "axios";
import { useMutation } from "react-query";

import { axiosInstance } from "./config";

const requests = {
  lock: (servId?: string) =>
    axiosInstance.post(`/service/${servId}/lock`).then((response) => response.data),
  unlock: (servId?: string) =>
    axiosInstance.post(`/service/${servId}/unlock`).then((response) => response.data),
};

const serviceService = {
  useLock: (servId?: string) =>
    useMutation<any, AxiosError>("lockServ" + (servId || "missing"), () =>
      requests.lock(servId)
    ),

  useUnlock: (servId?: string) =>
    useMutation<any, AxiosError>("unlockServ" + (servId || "missing"), () =>
      requests.unlock(servId)
    ),
};

export default serviceService;
