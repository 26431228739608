import { testTypes } from "types";
import { SiLighthouse, SiPlaywright } from "react-icons/si";
import { MdCompare } from "react-icons/md";
import { Science } from "@mui/icons-material";

const TestIcon = (props: {
  type?: testTypes.Test["type"];
  size?: "xl" | "large" | "medium" | "small";
  className?: string;
}) => {
  const numSize =
    props.size === "xl"
      ? "1.7em"
      : props.size === "large"
      ? "1.5em"
      : props.size === "medium"
      ? "1.45em"
      : "1.2em";

  switch (props.type) {
    case "playwright":
      return <SiPlaywright className={props.className} size={numSize} />;
    case "lighthouse":
      return <SiLighthouse className={props.className} size={numSize} />;
    case "visual-regression":
      return <MdCompare className={props.className} size={numSize} />;
    default:
      return <Science className={props.className} fontSize={props.size === "xl" ? "large" : props.size} />;
  }
};

export default TestIcon;
