import { Delete, Lock } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { repositoriesService } from "services";
import { repoTypes } from "types";
import Teaser from "./baseTeaser";

const PrivateRegistryTeaser = (
  privateRegistry: repoTypes.PrivateRegistry & {
    parentId: string;
    onDelete: () => any;
  }
) => {
  const { mutate: deleteRepoPrivateRegistry } =
    repositoriesService.useRemovePrivateRegistry(privateRegistry.parentId);

  const handleRemove = () => {
    deleteRepoPrivateRegistry(privateRegistry.address, {
      onSuccess: () => privateRegistry.onDelete(),
    });
  };

  return (
    <Teaser
      icon={<Lock className="!fill-brand" />}
      title={privateRegistry.address}
      info={
        <>
          <p className="text-neutral/70 pt-px leading-2">
            Username: <b>{privateRegistry.username}</b>
          </p>
        </>
      }
      actions={
        <IconButton onClick={handleRemove}>
          <Delete />
        </IconButton>
      }
    />
  );
};

export { PrivateRegistryTeaser };
