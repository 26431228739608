import { useEffect, useState } from "react";
import { getTimeDeltaEllapsedString } from "utils/timeLeftString";

const LiveCounter = (props: { startingTime: Date; pretext?: string }) => {
  const [time, setTime] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getTimeDeltaEllapsedString(props.startingTime));
    }, 100);

    return () => clearInterval(interval);
  }, [props.startingTime]);

  return (
    <span>
      {props.pretext} {time}
    </span>
  );
};

export default LiveCounter;
