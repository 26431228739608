import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";

function CircularProgressWithLabel(
  props: CircularProgressProps & {
    values: {
      value: number;
      label: string;
    }[];
    total?: number;
    label?: string;
    unit?: string;
  }
) {
  const getValueOffset = (index: number) => {
    let offset = props.values
      .sort((a, b) => a.value - b.value)
      .map((value) => value.value)
      .reduce((acc, value, i) => {
        if (i < index) {
          return acc + value;
        }
        return acc;
      }, 0);

    return (offset / (props.total ?? 100)) * 100;
  };

  const textClasses = `text-neutral leading-10 ${
    props.color === "primary" && "text-brand"
  } ${props.color === "error" && "text-status-error"} ${
    props.color === "warning" && "text-status-warning"
  } text-[40px] ${props.size && Number(props.size) <= 50 && "!text-m"}`;

  const unitClasses = `text-neutral leading-10 ${
    props.color === "primary" && "text-brand"
  } ${props.color === "error" && "text-status-error"} ${
    props.color === "warning" && "text-status-warning"
  } text-[20px] ${props.size && Number(props.size) <= 50 && "!text-s"}`;

  return (
    <div className="flex flex-col items-center">
      <Box
        sx={{
          position: "relative",
          height: props.size ?? 200,
          width: props.size ?? 200,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <CircularProgress
          variant="determinate"
          {...props}
          value={100}
          size={props.size ?? 200}
          className={"absolute !opacity-10"}
        />
        {props.values
          .sort((a, b) => a.value - b.value)
          .filter((value) => !value.label.includes("total"))
          .map((value) => (
            <Tooltip title={value.label} key={value.label}>
              <CircularProgress
                variant="determinate"
                {...props}
                value={
                  (value.value / (props.total ?? 100)) * 100 +
                  getValueOffset(props.values.indexOf(value))
                }
                size={props.size ?? 200}
                className={"absolute !opacity-40"}
              />
            </Tooltip>
          ))}
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="flex gap-xs items-end">
            <span className={textClasses}>
              {`${(props.total ?? props.values[0].value).toFixed()}`}
            </span>
            {props.unit && <span className={unitClasses}>{props.unit}</span>}
          </div>
          {(!props.size || Number(props.size) > 50) && (
            <p className="text-2xl text-neutral">{props.label}</p>
          )}
        </Box>
      </Box>
      {props.size && Number(props.size) <= 50 && !!props.label && (
        <p className="text-s text-neutral">{props.label}</p>
      )}
    </div>
  );
}

export default CircularProgressWithLabel;
