import { useEffect, useMemo } from "react";
import { EVENTS_WS_URL } from "./config";

const useWsSubscription = (
  subscription: Record<string, string>,
  onMessage: (data: any) => void
) => {
  let ws = useMemo(() => new WebSocket(EVENTS_WS_URL), []);
  if (subscription.id === "missing") {
    ws.close();
  } else {
    ws.onopen = () => {
      ws.send(JSON.stringify({ sub: subscription }));
    };
    ws.onmessage = (msg?: any) => {
      const data = JSON.parse(msg.data?.replaceAll("'", "\""))
      if (data?.update?.id === subscription.id) {
        onMessage(data);
      }
    };
  }
  useEffect(() => {
    return () => {
      ws.close();
    };
  }, [ws]);
};

const wsService = {
  useRepoSubscription: (id: string, onMessage: (data: any) => void) => {
    useWsSubscription({ id }, onMessage);
  },
  useEnvSubscription: (id: string, onMessage: (data: any) => void) => {
    useWsSubscription({ id }, onMessage);
  },
};

export default wsService;
