import { FaCheck, FaRegCircle, FaRightToBracket, FaStop, FaXmark } from "react-icons/fa6";

import { envTypes } from "types";
import Loader from "../Loader";

const EnvStatusIcon = (env: envTypes.Environment) => {
  switch (env.status) {
    case "softrebuilding":
    case "building":
      return <Loader />;
    case "failed":
      return <FaXmark />;
    case "built":
      return <FaCheck />;
    case "created":
      return <FaCheck />;
    case "creating":
      return <Loader />;
    case "cancelled":
      return <FaStop />;
    case "queued":
      return <FaRightToBracket/>;
    case "empty":
      return <FaRegCircle size={12} />;
    default:
      return null;
  }
};


export default EnvStatusIcon;