import CodeMirror from "@uiw/react-codemirror";
import { yaml } from "@codemirror/lang-yaml";
import { Buffer } from "buffer";
import { RefCallBack } from "react-hook-form";
import { Button, Link } from "@mui/material";
import { FaDrupal, FaReact } from "react-icons/fa6";
import { templates } from "utils";
import { useEffect } from "react";

const CodeEditor = (props: {
  onChange: (value: string) => void;
  onBlur?: () => void;
  value?: string;
  className?: string;
  ref?: RefCallBack;
  disabled?: boolean;
  showQaackFileTemplates?: boolean;
  label?: string;
}) => {
  const { value, onChange, className, showQaackFileTemplates, ...rest } = props;

  const onClickTemplate = (template: string) => {
    onChange(Buffer.from(template).toString("base64"));
  };

  return (
    <div className={className + " bg-white rounded relative pt-s"}>
      <p className="pl-s text-s text-neutral absolute -top-s">
        {props.label} (yml)
      </p>
      <CodeMirror
        {...rest}
        extensions={[yaml()]}
        minHeight="200px"
        value={Buffer.from(value ?? "", "base64").toString("utf-8")}
        onChange={(v) => {
          onChange(Buffer.from(v).toString("base64"));
        }}
      />
      {showQaackFileTemplates && (
        <QaackFileTemplates onClick={onClickTemplate} />
      )}
    </div>
  );
};


const qaackFileTemplates = [
  {
    name: "React",
    icon: <FaReact />,
    template: templates.React,
  },
  {
    name: "Drupal10",
    icon: <FaDrupal />,
    template: templates.Drupal10,
  },
];
const QaackFileTemplates = (props: { onClick: (template: string) => void }) => (
  <div className="flex gap-x-s pt-s pb-xs items-center text-s w-full flex-wrap gap-y-m">
    {qaackFileTemplates.map((template) => (
      <Button
        variant="outlined"
        size="small"
        key={template.name}
        onClick={() => props.onClick(template.template)}
        startIcon={template.icon}
      >
        {template.name}
      </Button>
    ))}
    <div className="ml-auto flex gap-x-s">
    <Link href="https://gitlab.com/qaack/showcases" target="_blank" className="!ml-auto !mr-s">
      More templates
    </Link> 
    <Link href="https://docs.qaack.cloud/specifications/qaack-file/" target="_blank" className="!ml-auto !mr-s">
      Qaack file docs
    </Link> 
    </div>
  </div>
);


export default CodeEditor;
